import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import "../components/styles/styles.scss"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import styled from "styled-components"

const IndexPage = props => (
  <Layout>
    <SEO title="Kathleen Hsu" />
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 120,
        paddingBottom: 50,
      }}
    >
      <h1>
        Hi, I'm Kathleen — a product designer with a background in cognitive
        science.
      </h1>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.platformDashboard.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Middesk Agent for Payroll Platforms</h2>
      <p>
        <a
          href="https://www.middesk.com/industries/payroll"
          target="_blank"
          rel="noreferrer"
        >
          Middesk Agent for Payroll Platforms
        </a>{" "}
        enables HR platforms to set up the tax accounts necessary for paying
        employees across states. I designed a dashboard experience for our
        partners, allowing them to invite their customers to register, track the
        registration progress for each customer, and receive notifications when
        tax IDs from government agencies are available. I also created a
        tailored onboarding experience to support partners integrating with our
        API.
      </p>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.employerDashboard.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Middesk Agent for Employers</h2>
      <p>
        <a
          href="https://www.middesk.com/industries/employer"
          target="_blank"
          rel="noreferrer"
        >
          Middesk Agent for Employers
        </a>{" "}
        helps businesses with payroll tax registrations and ongoing state
        compliance. For the public launch of the product, I designed a dashboard
        experience that would enable employers to easily register for payroll
        taxes and manage their state accounts. I've also designed new features
        for employers and our internal operations team, including state mail
        processing, annual report filings, and account transfers.
      </p>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.smartcarWebhooks.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Smartcar webhooks</h2>
      <p>
        <a href="https://smartcar.com" target="_blank" rel="noreferrer">
          Smartcar
        </a>{" "}
        is a developer platform that allows mobility businesses to connect with
        cars. Many developers wanted the ability to initiate API requests on the
        behalf of their customers, instead of having vehicle owners do so from
        their app. A common use case was that auto insurance customers wanted to
        retrieve their policyholders' vehicle odometer reading on a monthly
        basis. By setting up webhooks in Smartcar's developer dashboard,
        customers can automatically retrieve data from cars and have it sent to
        a URL on a scheduled basis.
      </p>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.smartcarConnect.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Smartcar Connect</h2>
      <p>
        Smartcar Connect is the interface that enables users to link their
        vehicles to a customers' app. I revamped the beta version of the flow
        with a clean and modern visual style, and designed new paths to help
        customers simplify the process for their users. I also focused on cases
        outside of the happy path, including helping vehicle owners discover
        whether their vehicle is compatible and create a connected services
        account.
      </p>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.smartcarWebsite.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Smartcar marketing site</h2>
      <p>
        I crafted a new visual system and refreshed the entire marketing site to
        communicate Smartcar's mission of "empowering developers to build the
        future of mobility" in a more inspiring and approachable way. The
        previous version of the site had a minimalistic design language that
        limited the ways the brand could be expressed. I aimed to create a brand
        identity that felt relatable, trustworthy, and polished.
      </p>
      <ImgWrapper>
        <Img
          loading="eager"
          fluid={props.data.coinbaseClawbacks.childImageSharp.fluid}
        />
      </ImgWrapper>
      <h2>Coinbase clawbacks</h2>
      <p>
        I was an intern at{" "}
        <a href="https://coinbase.com" target="_blank" rel="noreferrer">
          Coinbase
        </a>{" "}
        where I worked on the Consumer product. One of my largest projects was a
        redesign of the clawback experience to increase the number of users who
        pay back the funds they owe. In the original flow, users aren’t given
        information on why they have restrictions on their account, what their
        next steps are, or when the clawback process will begin. I designed a
        new flow for users in the clawback state to make the self-recovery
        process more effective and transparent.
      </p>
    </div>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    platformDashboard: file(
      relativePath: { eq: "payroll_platform_dashboard.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
    employerDashboard: file(relativePath: { eq: "employer_dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
    smartcarConnect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
    smartcarWebhooks: file(relativePath: { eq: "webhooks.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
    smartcarWebsite: file(relativePath: { eq: "marketing_website.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
    coinbaseClawbacks: file(relativePath: { eq: "coinbase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
  }
`

const ImgWrapper = styled.div`
  margin-top: 48px;
  @media (max-width: 1280px) {
    margin-top: 24px;
  }
  @media (max-width: 976px) {
    margin-top: 24px;
  }
`
